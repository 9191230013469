@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("primereact/resources/themes/lara-light-blue/theme.css"); // theme
@import url("primereact/resources/primereact.css"); // core css
@import url("primeicons/primeicons.css"); // icons

@font-face {
  font-family: Inter;
  src: url("./theme//font/inter/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: Inter;
  src: url("./theme//font/inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: Inter;
  src: url("./theme//font/inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: Inter;
  src: url("./theme//font/inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: Inter;
  src: url("./theme//font/inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: Inter;
  src: url("./theme//font/inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: Inter;
  src: url("./theme//font/inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: Inter;
  src: url("./theme//font/inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: Inter;
  src: url("./theme//font/inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: Fahkwang700;
  src: url("./theme/font/fahkwang/Fahkwang-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: Judson700;
  src: url("./theme/font/judson/Judson-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: Judson400;
  src: url("./theme/font/judson/Judson-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Archivo-black-400;
  src: url("./theme/font/archivo-black/ArchivoBlack-Regular.ttf")
    format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: AlfaSlabOne-Regular-400;
  src: url("./theme/font/alfaslabone/AlfaSlabOne-Regular.ttf")
    format("truetype");
  font-weight: 400;
}

body {
  margin: 0;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// @layer utilities {
//   @variants responsive {
//     /* Hide scrollbar for Chrome, Safari and Opera */
//     .no-scrollbar::-webkit-scrollbar {
//       display: none;
//     }

//     /* Hide scrollbar for IE, Edge and Firefox */
//     .no-scrollbar {
//       -ms-overflow-style: none; /* IE and Edge */
//       scrollbar-width: none; /* Firefox */
//     }
//   }
// }
